import { GetStaticProps, GetStaticPropsContext } from 'next';

import { BeehiivPublicationIds, BeehiivPublicationSlugs, beehiivServersideApi } from '@blockworks/platform/api/beehiiv';
import { WORD_PRESS_CATEGORY_MAPPING, wpApi } from '@blockworks/platform/api/wp';

import EditorialConfig from '@/editorial-config';

import { LatestPostsProps } from './layout/newsletter-page-layout';

export const newsletterMeta: { [key: string]: any } = {
    daily: {
        title: 'Blockworks Daily',
        description:
            'The newsletter that helps thousands of investors understand crypto and the markets, by Byron Gilliam.',
        og_image: [
            {
                width: 1330,
                height: 912,
                type: 'image/jpeg',
                url: `${EditorialConfig.SITE_URL}/images/newsletter/Daily_NewsletterPageOG.png`,
            },
        ],
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.Daily,
        postArchive: true,
    },
    weekly: {
        title: 'Blockworks Weekly',
        description:
            'The newsletter that helps thousands of investors understand crypto and the markets - Delivered weekly.',
        og_image: [
            {
                width: 1330,
                height: 912,
                type: 'image/jpeg',
                url: 'https://cms.blockworks.co/wp-content/uploads/2021/01/Blockworks_Newsletter_2.jpg?20221121',
            },
        ],
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.Weekly,
        postArchive: true,
    },
    research: {
        title: '0x Research',
        description:
            'Alpha directly in your inbox, including market highlights, charts, degen trade ideas, governance updates, token performance, can’t-miss-tweets and more, by Blockworks Research.',
        og_image: [
            {
                width: 1330,
                height: 912,
                type: 'image/jpeg',
                url: `${EditorialConfig.SITE_URL}/images/newsletter/0xResearch_NewsletterPageOG.png`,
            },
        ],
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.ResearchDaily,
        postArchive: true,
    },
    empire: {
        title: 'The Empire Newsletter',
        description:
            'Start your morning with the top news, analysis and data to inform your day in crypto, by the Blockworks News team.',
        og_image: [
            {
                type: 'image/png',
                url: `${EditorialConfig.SITE_URL}/images/newsletter/EmpireOG_v2.png`,
            },
        ],
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.Empire,
        postArchive: true,
    },
    lightspeed: {
        title: 'Lightspeed Newsletter',
        description:
            "A daily newsletter built for Solana investors, developers and community members. The latest news, analysis and data from one of crypto's hottest networks.",
        og_image: [
            {
                type: 'image/png',
                url: `${EditorialConfig.SITE_URL}/images/newsletter/Lightspeed_NewsletterPageOG_v2.png`,
            },
        ],
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.Lightspeed,
        postArchive: true,
    },
    forwardguidance: {
        title: 'Forward Guidance Newsletter',
        description:
            'Daily reporting, analysis and more on the growing intersection between crypto and macroeconomics, policy and finance.',
        twitter_card: 'summary_large_image',
        key: BeehiivPublicationIds.ForwardGuidance,
        postArchive: true,
    },
};

enum Platform {
    WordPress = 'wordpress',
    Beehiiv = 'beehiiv',
}

interface NewsMapping {
    [key: string]: Platform;
}

const LATEST_NEWS_MAPPING: NewsMapping = {
    [BeehiivPublicationSlugs.Daily]: Platform.Beehiiv,
    [BeehiivPublicationSlugs.Weekly]: Platform.Beehiiv,
    [BeehiivPublicationSlugs.ResearchDaily]: Platform.WordPress,
    [BeehiivPublicationSlugs.Empire]: Platform.WordPress,
    [BeehiivPublicationSlugs.Lightspeed]: Platform.WordPress,
    [BeehiivPublicationSlugs.ForwardGuidance]: Platform.WordPress,
};

export const NewsletterPublicationStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {
    const slug: string = context.params?.slug as string;
    if (!slug) {
        return {
            notFound: true,
            revalidate: 600,
        };
    }

    let latest: LatestPostsProps[] | null;

    if (newsletterMeta[slug].postArchive === true) {
        const mappedValue = LATEST_NEWS_MAPPING[slug];

        switch (mappedValue) {
            case Platform.Beehiiv:
                latest = await beehiivServersideApi.get
                    .posts({
                        publication: newsletterMeta[slug].key ?? BeehiivPublicationIds.Daily,
                        expand: ['free_email_content'],
                        audience: 'all',
                        platform: 'all',
                        status: 'confirmed',
                        limit: 24,
                        order_by: 'publish_date',
                        direction: 'desc',
                    })
                    .then(posts => {
                        return (
                            posts?.data?.map(post => ({
                                id: post.id ?? null,
                                publishDate: String(post.publish_date),
                                thumbnailUrl: post.thumbnail_url ?? null,
                                subject: post.subject_line ?? null,
                                linkTo: `/newsletter/${slug}/issue/${post.id}`,
                            })) ?? null
                        );
                    });
                break;
            case Platform.WordPress:
                latest = await wpApi.get
                    .postsByCategory({
                        perPage: 24,
                        order: 'desc',
                        orderBy: 'date',
                        categories: WORD_PRESS_CATEGORY_MAPPING[slug] ? [WORD_PRESS_CATEGORY_MAPPING[slug]] : [],
                    })
                    .then(posts => {
                        return (
                            posts.map(post => ({
                                id: String(post.id),
                                publishDate: post.date,
                                thumbnailUrl: null,
                                subject: post.title.rendered,
                                linkTo: post.link,
                            })) ?? null
                        );
                    });
                break;
            default:
                latest = null;
                break;
        }
    } else {
        latest = null;
    }

    return {
        props: {
            meta: newsletterMeta[slug] ?? null,
            latest: latest,
        },
        revalidate: 1800,
    };
};

export default NewsletterPublicationStaticProps;
