import * as React from 'react';
import { useRouter } from 'next/router';

import { BeehiivPublicationIds } from '@blockworks/platform/api/beehiiv';

import { withExceptionCatcher } from '@/components/hoc/with-exception-catcher';
import NewsletterPageLayout, {
    LatestPostsProps,
} from '@/components/pages/newsletter/publication/layout/newsletter-page-layout';
import { newsletterMeta } from '@/components/pages/newsletter/publication/newsletter-publication-static-props';
import subscribeToNewsletter from '@/utils/functions/subscribe-to-newsletter';

export interface NewsletterDailyPageControllerProps {
    latest: LatestPostsProps[];
}

export const NewsletterPublicationController = (props: NewsletterDailyPageControllerProps) => {
    const router = useRouter();
    const slug: string = router.query.slug as string;
    const source = router.query?.utm_source ? `${router.query?.utm_source}` : `newsletter-${slug}-page`;
    const campaign = router.query?.utm_campaign ? `${router.query?.utm_campaign}` : '';
    const medium = router.query?.utm_medium ? `${router.query?.utm_medium}` : 'organic';

    if (!newsletterMeta[slug]) {
        return null;
    }

    // default subscribe to all newsletters
    let subscribeTo = [newsletterMeta[slug].key];
    let imageUrl = '/images/newsletter/Daily_NewsletterPage_v2.png';
    let frequency = 'Weekdays';

    switch (slug) {
        case 'daily-debrief-by-blockworks-research':
        case 'research':
            imageUrl = '/images/newsletter/0xResearch_NewsletterPage_v2.png';
            frequency = 'Weekdays';
            break;
        case 'empire':
            imageUrl = '/images/newsletter/Empire_NewsletterPage_v3.png';
            break;
        case 'lightspeed':
            imageUrl = '/images/newsletter/Lightspeed_NewsletterPage_v2.png';
            break;
        case 'forwardguidance':
            imageUrl = '/images/newsletter/Forwardguidance_NewsletterPage.png';
            break;
        case 'weekly':
            frequency = 'Weekly';
            break;
        default:
            subscribeTo = [
                BeehiivPublicationIds.Weekly,
                BeehiivPublicationIds.Daily,
                BeehiivPublicationIds.Events,
                BeehiivPublicationIds.Webinars,
                BeehiivPublicationIds.ForwardGuidance,
            ];
            break;
    }

    return (
        <NewsletterPageLayout
            latest={props.latest}
            subscribeCallback={email =>
                subscribeToNewsletter(email, subscribeTo, source, router.asPath, campaign, medium)
            }
            frequency={frequency}
            imageUrl={imageUrl}
            title={newsletterMeta[slug].title}
            description={newsletterMeta[slug].description}
        />
    );
};

export default withExceptionCatcher(NewsletterPublicationController);
